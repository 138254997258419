const lojista = "Lojista";
const documentoLojista = "Documento Lojista";
const valorLiquido = "Valor Líquido";
const proposta = "Proposta";
const valorBruto = "Valor Bruto";
const recebido = "Recebido";
const aReceber = "A Receber";
export const worksheetBoxes = [
    {
        title: "Transações",
        description: "Detalha as informações como valor, previsão de pagamento e data efetiva de pagamento das transações splitadas dos pagadores associados ao recebedor.",
        tableContents: [
            {
                title: "StoneID",
                description: "O identificador único da transação na Stone."
            },
            {
                title: "UnioID",
                description: "O identificador único da transação no Unio."
            },
            {
                title: "Tipo",
                description: "Débito, crédito ou pré-pago."
            },
            {
                title: "Parcela",
                description: "O número sequencial da parcela na transação."
            },
            {
                title: "Quantidade Parcelas",
                description: "A quantidade total de parcelas associadas à transação."
            },
            {
                title: "Stonecode Lojista",
                description: "O Stonecode do lojista associado à transação."
            },
            {
                title: lojista,
                description: "O nome do lojista associado à transação."
            },
            {
                title: documentoLojista,
                description: "O documento do lojista associado à transação."
            },
            {
                title: "Stonecode Recebedor",
                description: "O Stonecode do credor associado à transação."
            },
            {
                title: "Documento Recebedor",
                description: "O documento do credor associado à transação."
            },
            {
                title: valorLiquido,
                description: "O valor líquido da transação após deduções ou taxas."
            },
            {
                title: "Data da Venda",
                description: "A data em que a venda foi efetuada pelo lojista, no formato AAAA-MM-DD."
            },
            {
                title: "Hora da Venda",
                description: "O horário em que a venda foi efetuada pelo lojista no fuso horário de Brasília, no formato HH:MM:SS."
            },
            {
                title: "Previsão de Pagamento",
                description: "A data em que é prevista a liquidação da transação para o credor, no formato AAAA-MM-DD."
            },
            {
                title: "Mês Pagamento",
                description: "Representa o mês e o ano em que é estimada a liquidação da transação para o credor, no formato AAAA-MM."
            },
            {
                title: "Data de Pagamento",
                description: "A data em que a transação é efetivamente liquidada para o credor, no formato AAAA-MM-DD."
            },
            {
                title: proposta,
                description: "O identificador único da proposta do Unio que originou o recebimento para o credor."
            }
        ]
    },
    {
        title: "Direcionado por Proposta",
        description: 'Apresenta uma visão consolidada dos valores splitados por proposta, baseada na planilha "Transações".',
        tableContents: [
            {
                title: proposta,
                description: "O identificador único da proposta do Unio."
            },
            {
                title: documentoLojista,
                description: "O documento do lojista associado à proposta."
            },
            {
                title: lojista,
                description: "O nome do lojista associado à proposta."
            },
            {
                title: valorLiquido,
                description: "A soma dos valores líquidos de todas as transações associadas à proposta."
            },
            {
                title: recebido,
                description: "A soma dos valores já liquidados ao credor até a data de geração do relatório, associados à proposta."
            },
            {
                title: aReceber,
                description: "A soma dos valores que o credor tem a receber após a data de geração do relatório, associados à proposta."
            }
        ]
    },
    {
        title: "Direcionada por Mês",
        description: "Apresenta uma visão consolidada dos valores splitados de acordo com o mês previsto para pagamento.",
        tableContents: [
            {
                title: proposta,
                description: "O identificador único da proposta do Unio."
            },
            {
                title: documentoLojista,
                description: "O documento do lojista associado à proposta."
            },
            {
                title: lojista,
                description: "O nome do lojista associado à proposta."
            },
            {
                title: "Mês de Pagamento",
                description: "O ano e o mês associados aos valores apresentados, no formato AAAA-MM."
            },
            {
                title: valorLiquido,
                description: "A soma dos valores líquidos de todas as transações associadas à proposta e o mês de pagamento."
            },
            {
                title: recebido,
                description: "A soma dos valores já liquidados ao credor associados à proposta e o mês de pagamento."
            },
            {
                title: aReceber,
                description: "A soma dos valores que o credor tem a receber associados à proposta e o mês de pagamento."
            }
        ]
    }
]
